
import './App.scss';
import Video from './components/Video';
import { useState } from 'react';

function App() {
const [buttonPopup, setButtonPopup] = useState(false);
 return (
    <main className="main">
      <header className="header">
        
        <h1 className="header__top">
          50
          
        </h1>
        <h2 className="header__lower">
            years
          </h2>
        <button className="button" onClick={() => setButtonPopup(true)}>Watch the video</button>
        <div class="box">
        <a href="https://drive.google.com/drive/folders/1hc2ix6ekD6tY9cxCkU388P5ynjcFMixMYczluxi6aEf-D6dV9pF0eTaPMnBeSbUpAYEYXy_H?usp=sharing" className="link">See all unedited clips</a>
        </div>
        
      </header>
      <Video trigger={buttonPopup} setTrigger={setButtonPopup} />
    </main>
  );
}

export default App;
