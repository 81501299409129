import React from 'react';
import './Video.scss';

const Video = (props) => {

    return (props.trigger) ? (
        <div className="video">
            <div className="video__inner">
                <iframe className="iframe" title="HBD Mom" src="https://player.vimeo.com/video/905834717?h=f3a40cd41f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen"></iframe>
                <button onClick={() => props.setTrigger(false)} className="close-btn">
                    <div className="x">
                        X
                    </div>
                </button>
            </div>
            {props.children}
        </div>
    ) : "";

};

export default Video;
